// Here you can add other styles
.mat-dialog-container {
  max-height: 75vh !important;
}

// .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
//   color: rgba(63, 63, 63, 0.8)!important; 
// }
.mat-select-disabled .mat-select-value {
  color: rgba(0,0,0,1)!important;
}

.mat-form-field-type-mat-native-select .mat-form-field-disabled .mat-form-field-infix::after, .mat-input-element:disabled {
  color: rgba(0,0,0,1)!important;
}

// .mat-form-field-infix > span > label > span {
//   color: black;
//   background-color: seashell;
//   padding: 5px;
//   border-radius: 3px;
// }

.mat-form-field-infix > span > label > span {
  color: rgba(60,60,60,0.8);
}